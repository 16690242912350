import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { getChannels } from "../../shared/services/channel/channel.service";
import Channel1Icon from "./../../../assets/images/channel1.svg";
import Channel2Icon from "./../../../assets/images/channel2.svg";
import Channel3Icon from "./../../../assets/images/channel3.svg";
import Channel4Icon from "./../../../assets/images/channel4.svg";
import Channel5Icon from "./../../../assets/images/channel5.svg";
import styles from "./index.module.less";

const ChannelIcons = [
    Channel1Icon,
    Channel2Icon,
    Channel3Icon,
    Channel4Icon,
    Channel5Icon,
];

const Channels = ({
    showAll = false,
    showTitle = true,
    isWidgetContainer = false,
}) => {
    const [isMobile] = useState(window.outerWidth < 768);

    const [channels, setChannels] = useState<any>([]);

    const [limit, setLimit] = useState(showAll ? 100 : 5);

    useEffect(() => {
        getChannels()
            .then((resp) => {
                setChannels(resp.response.data);
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, []);
    return (
        <div className={"space-y-4"} id={"channels"}>
            {showTitle ? (
                <div className={"text-2xl text-primary-white font-bold"}>
                    Channels
                </div>
            ) : null}
            <div
                className={`${
                    isMobile
                        ? " flex overflow-auto gap-6"
                        : "responsive-grid-wrapper lg:grid-cols-3"
                } `}
            >
                {channels.map((channel: any, index: number) => {
                    return (
                        <div
                            key={channel.id}
                            className={styles.channelBox}
                            style={{
                                minHeight: isWidgetContainer ? 250 : 375,
                                minWidth: 250,
                            }}
                        >
                            <div className="md:px-4">
                                <div className="flex justify-center mb-4">
                                    <img
                                        src={
                                            ChannelIcons[index] ||
                                            ChannelIcons[0]
                                        }
                                        alt="Channel IconS"
                                    />
                                </div>
                                <div
                                    className={`text-primary-white ${
                                        isWidgetContainer
                                            ? "text-lg"
                                            : "text-2xl"
                                    }`}
                                    style={{
                                        minHeight: 100,
                                        width: isWidgetContainer ? 120 : 180,
                                    }}
                                >
                                    {channel.name}
                                </div>
                                <Link
                                    to={`/channel/${channel.id}`}
                                    className={`secondary-button small ${styles["secondary-button"]}`}
                                >
                                    visit channel
                                </Link>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Channels;
