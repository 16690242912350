import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import ChannelsModal from "./ChannelsModal";
import styles from "./index.module.less";

const Footer = () => {
    const history = useHistory();

    const [pathName, setPathName] = useState("/");

    const [showChannelModal, setShowChannelModal] = useState(false);

    useEffect(() => {
        setPathName(history.location.pathname);

        const historyListener = history.listen((location, action) => {
            setPathName(history.location.pathname);
        });

        return () => {
            historyListener();
        };
    }, []);

    return (
        <div className="flex md:justify-between items-center pb-20 pt-10 flex-col md:flex-row gap-4">
            <div>Stiry copyright © {new Date().getFullYear()}</div>
            <div className="flex gap-10 text-xs">
                <Link
                    to="/"
                    className={`${styles.footerLink} ${
                        pathName === "/" && styles.footerLinkActive
                    }`}
                >
                    home
                </Link>
                <Link
                    to="/watchlist"
                    className={`${styles.footerLink} ${
                        pathName === "/watchlist" && styles.footerLinkActive
                    }`}
                >
                    watchlist
                </Link>
                <Link
                    to="/favorites"
                    className={`${styles.footerLink} ${
                        pathName === "/favorites" && styles.footerLinkActive
                    }`}
                >
                    favorites
                </Link>
                <div
                    className={styles.footerLink + " cursor-pointer"}
                    onClick={() => setShowChannelModal(true)}
                >
                    channels
                </div>
                <Link
                    to="/privacy-policy"
                    className={`${styles.footerLink} ${
                        pathName === "/privacy-policy" && styles.footerLinkActive
                    }`}
                >
                    Policy
                </Link>
                {/* <Link to="/" className={styles.footerLink}>
                    videos
                </Link>
               
                <Link to="/" className={styles.footerLink}>
                    video podcasts
                </Link>
                <Link to="/" className={styles.footerLink}>
                    audio podcasts
                </Link>
                <Link to="/" className={styles.footerLink}>
                    inspirational quotes
                </Link> */}
            </div>
            <ChannelsModal
                showModal={showChannelModal}
                onClose={() => setShowChannelModal(false)}
            />
        </div>
    );
};

export default Footer;
