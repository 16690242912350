import React, { useContext, useEffect, useState } from "react";
import FeaturedAccordian from "./FeaturedAccordian";
import Channels from "./Channels";
import Recommended from "./Recommended";
import NewOnStiry from "./NewOnStiry";
import { Form, Button, Checkbox } from "antd";
import { getSocialMedias } from "../../shared/services/content/content.service";
import Reels from "./Reels";
import { AuthContext } from "../../shared/context/auth";
import FindYourInspiration from "./FindYourInspiration";
import Footer from "../../shared/components/Footer";

const Dashboard = () => {
    const [isMobile] = useState(window.outerWidth < 768);

    const [isFilterVisible, setIsFilterVisible] = useState(false);

    const [filters, setFilters] = useState({});

    const socialMedias = getSocialMedias();

    const { authState } = useContext(AuthContext);

    const [isUser, setIsUser] = useState(false);

    useEffect(() => {
        setIsUser(authState?.user?.role?.name?.toLowerCase() === "user");
    }, [authState]);

    const menu = (
        <div className={"bg-primary-white p-4 shadow-lg rounded"}>
            <Form
                name={"search"}
                onFinish={(values) => {
                    setIsFilterVisible(false);
                    setFilters((currentState) => {
                        return {
                            ...currentState,
                            ...values,
                        };
                    });
                }}
                onReset={() => {
                    setFilters({});
                    setIsFilterVisible(false);
                }}
            >
                <div className={"flex gap-5 "}>
                    <div
                        className={
                            "flex-1 space-y-2 border-r border-secondary-blue-500"
                        }
                    >
                        <div className={"text-xs font-bold"}>Content Type</div>
                        <Form.Item name="subCategory">
                            <Checkbox.Group>
                                <div>
                                    <Checkbox value="STIRY_STORY">
                                        Stiry+ Original Story{" "}
                                    </Checkbox>
                                </div>
                                <div>
                                    <Checkbox value="SHORT">Short </Checkbox>
                                </div>
                                <div>
                                    <Checkbox value="VIDEO_PODCAST">
                                        Video Podcast{" "}
                                    </Checkbox>
                                </div>
                                {/* <div>
                                    <Checkbox value="REELS">Reels </Checkbox>
                                </div> */}
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item name="mediaType">
                            <Checkbox.Group>
                                <div>
                                    <Checkbox value="AUDIO">Audio </Checkbox>
                                </div>
                                <div>
                                    <Checkbox value="IMAGE">Image </Checkbox>
                                </div>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>

                    <div
                        className={
                            "flex-1 space-y-2 border-r border-secondary-blue-500 "
                        }
                    >
                        <div className={"text-xs font-bold"}>Length</div>
                        <Form.Item name="length">
                            <Checkbox.Group>
                                <div>
                                    <Checkbox value=">0<30">
                                        less than 30 seconds
                                    </Checkbox>
                                </div>
                                <div>
                                    <Checkbox value=">30<60">
                                        30 sec - 1 min
                                    </Checkbox>
                                </div>
                                <div>
                                    <Checkbox value=">60<120">
                                        1 min - 2 min
                                    </Checkbox>
                                </div>
                                <div>
                                    <Checkbox value=">120<300">
                                        2 min - 5 min
                                    </Checkbox>
                                </div>
                                <div>
                                    <Checkbox value=">300<600">
                                        5 min - 10 min
                                    </Checkbox>
                                </div>
                                <div>
                                    <Checkbox value=">600<1200">
                                        10 min - 20 min
                                    </Checkbox>
                                </div>
                                <div>
                                    <Checkbox value=">1200">20 min +</Checkbox>
                                </div>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    {!isUser && (
                        <div className={"flex-1"}>
                            <div className={"text-xs font-bold"}>
                                Social Media Type
                            </div>
                            <Form.Item name="socialMediaUsage">
                                <Checkbox.Group>
                                    {socialMedias.map((socialMedia) => {
                                        return (
                                            <div key={socialMedia}>
                                                <Checkbox value={socialMedia}>
                                                    {socialMedia}
                                                </Checkbox>
                                            </div>
                                        );
                                    })}
                                </Checkbox.Group>
                            </Form.Item>
                        </div>
                    )}
                </div>

                <div className={"flex justify-end mt-4 gap-4"}>
                    <Button
                        type="primary"
                        ghost
                        className={"rounded"}
                        htmlType="reset"
                    >
                        Clear Filters
                    </Button>
                    <Button
                        type="primary"
                        className={"rounded"}
                        htmlType="submit"
                    >
                        Apply Filters
                    </Button>
                </div>
            </Form>
        </div>
    );
    return (
        <div className="absolute top-0 left-0 right-0 dashboard-index">
            <FeaturedAccordian />

            <div
                style={{
                    background:
                        "linear-gradient(0deg, rgba(18,25,32,1) 45%, rgba(18,25,32,0) 100%)",
                    height: 200,
                    top: isMobile ? "50vh" : "60vh",
                }}
                className="absolute left-0 right-0 z-10"
            ></div>

            <div
                className="absolute left-6 right-6 md:left-44 md:right-12 z-10 space-y-24 bg-no-repeat bg-contain "
                style={{
                    top: isMobile ? "52vh" : "70vh",

                    // backgroundImage: `url(${homeBG})`,
                    // backgroundPositionY: 300,
                }}
            >
                <Reels filters={filters} />
                <Recommended filters={filters} />
                <NewOnStiry filters={filters} />
                <FindYourInspiration />
                <Channels />
                <Footer />
                <div
                    style={{
                        height: 180,
                        bottom: 0,
                        right: isMobile ? -24 : -40,
                        left: isMobile ? -24 : -200,
                        zIndex: -1,
                        backgroundColor: "#0f141a",
                    }}
                    className="absolute"
                ></div>
            </div>
        </div>
    );
};

export default Dashboard;
