import React, { useEffect, useState } from "react";
import { uniqBy } from "lodash";
import {
    getAllContents,
    mapContentWithLength,
} from "../../shared/services/content/content.service";
import ContentItem from "../dashboard/ContentItem";
import { getChannelById } from "../../shared/services/channel/channel.service";
import { useRouteMatch } from "react-router";
import Footer from "../../shared/components/Footer";

const SeeAll = () => {
    const queryParams = new URLSearchParams(window.location.hash.split('?')[1]);

    const { params } = useRouteMatch<{ id: string }>();

    const [type] = useState(queryParams.get("type") || "");

    const [search] = useState(queryParams.get("search") || "");

    const [subCategory] = useState(queryParams.get("subCategory") || "");

    const [contents, setContents] = useState<any>([]);

    const [channel, setChannel] = useState<any>(null);

    const [paginationParams, setPaginationParams] = useState({
        skip: 0,
        limit: 30,
        pageNumber: 1,
    });

    const [hasNextPage, setHasNextPage] = useState(true);

    const [fetchTrigger, setFetchTrigger] = useState(0);

    useEffect(() => {
        const scrollCallback = (ev: any) => {
            if (
                window.innerHeight + window.scrollY >=
                    document.body.scrollHeight &&
                hasNextPage
            ) {
                setPaginationParams((currentState) => {
                    return {
                        ...currentState,
                        skip: currentState.pageNumber * currentState.limit,
                    };
                });

                setFetchTrigger((currentState) => {
                    return currentState + 1;
                });
            }
        };

        window.addEventListener("scroll", scrollCallback);

        return () => {
            window.removeEventListener("scroll", scrollCallback, false);
        };
    }, [hasNextPage]);

    useEffect(() => {
        if (params.id) {
            getChannelById(params.id)
                .then((resp) => {
                    setChannel(resp.response);
                })
                .catch((err) => {
                    console.log("🚀 ~ err", err);
                });
        }
    }, [params]);

    useEffect(() => {
        getAllContents(
            {
                mediaType: type.toUpperCase(),
                search,
                channels: [params.id],
                subCategory: (subCategory || undefined)?.toUpperCase(),
            },
            {
                skip: paginationParams.skip,
                limit: paginationParams.limit,
            }
        )
            .then((resp) => {
                setContents((currentState: any) => {
                    return uniqBy(
                        [
                            ...currentState,
                            ...mapContentWithLength(resp.response.data),
                        ],
                        (x) => x.id
                    );
                });

                setPaginationParams((currentState) => {
                    return {
                        ...currentState,
                        pageNumber: currentState.pageNumber + 1,
                    };
                });

                setHasNextPage(resp.response.pagingMeta.hasNextPage);
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, [fetchTrigger]);

    return (
        <div className="md:absolute left-44 right-12 pt-10 flex flex-col min-h-screen-stiry px-6 md:px-0">
            <div className={"space-y-6"}>
                <div className="flex gap-10 items-baseline">
                    <div className={`text-2xl md:text-3xl text-primary-white font-bold`}>
                        {channel?.name}
                    </div>
                </div>
                <div className={"responsive-grid-wrapper"}>
                    {contents.map((content: any) => {
                        return (
                            <ContentItem content={content} key={content.id} />
                        );
                    })}
                </div>

                {!hasNextPage ? (
                    <div className="py-10">End of contents!</div>
                ) : null}
            </div>
            <div className="flex-grow flex flex-col justify-end">
                <Footer />
            </div>
        </div>
    );
};

export default SeeAll;
