import React, { useEffect, useState, useRef } from "react";
import {
    getFeaturedContent,
    mapContentWithLength,
} from "../../shared/services/content/content.service";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import styles from "./index.module.less";
import { ReactComponent as ClockIcon } from "./../../../assets/images/clock.svg";
import { ReactComponent as PlayIcon } from "./../../../assets/images/play.svg";

SwiperCore.use([Autoplay]);

const FeaturedAccordian = () => {
    const [featuredContents, setFeaturedContents] = useState<any>([]);
    const swiperRef = useRef<any>(null);
    
    useEffect(() => {
        getFeaturedContent()
            .then((resp) => {
                setFeaturedContents(() => {
                    return [...mapContentWithLength(resp.response.data)];
                });
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, []);

    const resetGif = () => {
        const swiperInstance = swiperRef.current;
        if (swiperInstance) {
            const currentSlide = swiperInstance.slides[swiperInstance.activeIndex];
            const gifElement = currentSlide.querySelector("img[src$='.gif']");
            if (gifElement) {
                gifElement.src = gifElement.src;
            }
        }
    };

    return (
        <Swiper
            autoplay={{
                delay: 5400,
            }}
            loop={true}
            className={`absolute left-0 top-0 right-0  ${styles.featuredHeight}`}
            onSlideChange={resetGif}
            onSwiper={(swiper) => {
                swiperRef.current = swiper;
            }}
        >
            {featuredContents.map((content: any) => {
                return (
                    <SwiperSlide key={content.id} className={""}>
                        <Link
                            to={`/content/${content.id}`}
                            className={`bg-center bg-cover h-full absolute left-0 top-0 right-0`}
                        >
                            <img
                                style={{
                                    width: "-webkit-fill-available",
                                }}
                                src={
                                    content.coverFeaturedContent
                                        ? content.coverFeaturedContent.url
                                        : content.cover.url
                                }
                                alt=""
                                className={`bg-center bg-cover h-full absolute left-0 top-0 right-0`}
                            />

                            <div
                                style={{
                                    background:
                                        "radial-gradient(circle, rgba(0,0,0,0) 00%, rgba(18,25,32,1) 80%)",
                                }}
                                className="absolute top-0 left-0 right-0 h-full"
                            >
                                <div className="absolute top-40 left-4 md:left-44  space-y-6">
                                    <span className="uppercase text-secondary-dark bg-primary-white rounded py-1 px-2 text-xs">
                                        featured content
                                    </span>
                                    <div className="text-2xl md:text-7xl font-bold text-primary-white">
                                        {content.title}
                                    </div>
                                    <div className=" text-primary-white line-clamp-4 md:w-2/4 w-3/4">
                                        {content.description}
                                    </div>
                                    <div className="space-y-1">
                                        <Link
                                            to={`/content/${content.id}/watch`}
                                            className="flex gap-2 items-center"
                                        >
                                            <PlayIcon
                                                className={styles.redPlayIcon}
                                            />
                                            <span className="text-primary-white">
                                                Watch Video
                                            </span>
                                        </Link>
                                        <div className="flex gap-2 items-center text-primary-white-light">
                                            <div>
                                                <ClockIcon />
                                            </div>
                                            <div className="mt-0.5">
                                                {content.duration}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

export default FeaturedAccordian;
