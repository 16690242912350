import React, { useContext } from "react";
import { AuthActions, AuthContext } from "../../shared/context/auth";
import {
    ILoginPayload,
    loginRequest,
} from "../../shared/services/auth/auth.service";
import { Form, Input, Button, Row } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { HashLink as Link } from "react-router-hash-link";

const Login = () => {
    const { dispatch } = useContext(AuthContext);

    const onFinish = (values: ILoginPayload) => {
        loginRequest(values)
            .then((data: any) => {
                dispatch({
                    action: AuthActions.SETTOKEN,
                    data: {
                        token: {
                            access_token: data.response.access_token,
                            refresh_token: data.response.refresh_token,
                        },
                    },
                });

                dispatch({
                    action: AuthActions.SETUSER,
                    data: {
                        user: data.response.user,
                    },
                });
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    };

    return (
        <Form
            name="Login"
            initialValues={{ email: "", password: "", remember: true }}
            onFinish={onFinish}
        >
            <Form.Item
                name="email"
                rules={[
                    { required: true, message: "Please input your Email!" },
                ]}
            >
                <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    { required: true, message: "Please input your Password!" },
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Row justify={"end"} className={"mb-4"}>
                <Link to={"/auth/forgot-password"}>Forgot password</Link>
            </Row>

            <Form.Item>
                <button type="submit" className="primary-button w-full">
                    Sign In
                </button>
                <div className={"text-center mt-2"}>
                    Or <Link to={"/auth/register"}>register now!</Link>
                </div>
            </Form.Item>
        </Form>
    );
};

export default Login;
