import React, { useContext, useEffect, useState } from "react";
import {
    CloseOutlined,
    DownloadOutlined,
    HeartFilled,
    HeartOutlined,
    PlayCircleOutlined,
    ShareAltOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import styles from "./index.module.less";
import {
    downloadVimeoVideo,
    removeFavoriteContent,
    removeWatchlistContent,
    saveContentDownload,
    saveFavoriteContent,
    saveWatchlistContent,
} from "../../shared/services/content/content.service";
import { toast } from "react-toastify";
import { Button, Modal, Tooltip } from "antd";
import {
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton,
    RedditShareButton,
    PinterestShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import facebookIcon from "./../../../assets/images/facebook.svg";
import twitterIcon from "./../../../assets/images/twitter.svg";
import emailIcon from "./../../../assets/images/email.svg";
import redditIcon from "./../../../assets/images/reddit.svg";
import pinterestIcon from "./../../../assets/images/pinterest.svg";
import copyIcon from "./../../../assets/images/copy.svg";
import { AuthContext } from "../../shared/context/auth";
import { ReactComponent as WatchlistIcon } from "./../../../assets/images/watchlist.svg";
import { ReactComponent as DownloadIcon } from "./../../../assets/images/download.svg";
import { ReactComponent as FavoriteOutlineIcon } from "./../../../assets/images/favorite-outline.svg";
import { ReactComponent as FavoriteFilledIcon } from "./../../../assets/images/favorite.svg";
import { ReactComponent as PlayIcon } from "./../../../assets/images/play.svg";
import { first, last, split } from "lodash";

const ContentActions = ({ content, isWatch = false }: any) => {
    const { authState } = useContext(AuthContext);

    const [isFavorite, setIsFavorite] = useState(content.favorite);

    const [isWatchlist, setIsWatchlist] = useState(content.watchlist);

    const [isShareModalVisible, setIsShareModalVisible] = useState(false);

    const previewItemStyle = isWatch
        ? styles.previewItemWatch
        : styles.previewItem;

    const [isUser, setIsUser] = useState(false);

    useEffect(() => {
        setIsUser(authState?.user?.role?.name?.toLowerCase() === "user");
    }, [authState]);

    return (
        <div className="flex items-center justify-between gap-10">
            <div className="flex items-center gap-4">
                <Tooltip title={"Watch now"}>
                    <Link
                        to={`/content/${content.id}/watch`}
                        className="play-button"
                    >
                        <PlayIcon className={previewItemStyle} />
                    </Link>
                </Tooltip>
                <div
                    className={`cursor-pointer flex items-center gap-2 hover:text-primary-white ${styles.previewItem}`}
                    onClick={() => {
                        const request = isWatchlist
                            ? removeWatchlistContent(content.id)
                            : saveWatchlistContent(content.id);

                        request
                            .then((resp) => {
                                if (isWatchlist) {
                                    toast.success(
                                        "Content removed from watchlist."
                                    );
                                } else {
                                    toast.success(
                                        "Content added to watchlist."
                                    );
                                }
                                setIsWatchlist(!isWatchlist);
                            })
                            .catch((err) => {
                                console.log("🚀 ~ err", err);
                            });
                    }}
                >
                    <WatchlistIcon />
                    <span className="text-xs whitespace-nowrap">
                        {!isWatchlist ? "Add to " : "Remove from "} Watchlist
                    </span>
                </div>
            </div>
            <div className="flex items-center gap-4">
                <Tooltip
                    title={
                        isFavorite
                            ? "Remove from Favorites"
                            : "Add to Favorites"
                    }
                >
                    <div
                        className={`cursor-pointer`}
                        onClick={() => {
                            const request = isFavorite
                                ? removeFavoriteContent(content.id)
                                : saveFavoriteContent(content.id);

                            request
                                .then((resp) => {
                                    if (isFavorite) {
                                        toast.success(
                                            "Content removed from favorite."
                                        );
                                    } else {
                                        toast.success(
                                            "Content set as favorite."
                                        );
                                    }
                                    setIsFavorite(!isFavorite);
                                })
                                .catch((err) => {
                                    console.log("🚀 ~ err", err);
                                });
                        }}
                    >
                        {isFavorite ? (
                            <FavoriteFilledIcon className={previewItemStyle} />
                        ) : (
                            <FavoriteOutlineIcon className={previewItemStyle} />
                        )}
                    </div>
                </Tooltip>

                {content.isDownloadable && !isUser && (
                    <Tooltip title="Download">
                        <div
                            className={`cursor-pointer`}
                            onClick={() => {
                                saveContentDownload(
                                    authState.user.id,
                                    content.id
                                )
                                    .then(() => {
                                        new Promise<string>((resolve) => {
                                            if (
                                                content.media.url.includes(
                                                    "vimeo"
                                                )
                                            ) {
                                                const videoId = first(
                                                    split(
                                                        last(
                                                            split(
                                                                content.media
                                                                    .url,
                                                                "/"
                                                            )
                                                        ),
                                                        "?h"
                                                    )
                                                );

                                                if (videoId) {
                                                    downloadVimeoVideo(
                                                        videoId
                                                    ).then((resp) => {
                                                        const downloadLink =
                                                            resp.response.link;

                                                        resolve(downloadLink);
                                                    });
                                                } else {
                                                    toast.error(
                                                        "Failed to extract video ID."
                                                    );
                                                }
                                            } else {
                                                resolve(content.media.url);
                                            }
                                        }).then((url: string) => {
                                            var a: any =
                                                document.createElement("a");
                                            a.href = url;
                                            a.target = "_blank";
                                            a.download = url.substr(
                                                url.lastIndexOf("/") + 1
                                            );
                                            document.body.appendChild(a);
                                            a.click();
                                            document.body.removeChild(a);
                                        });
                                    })
                                    .catch((err) => {
                                        console.log("🚀 ~ err", err);
                                    });
                            }}
                        >
                            <DownloadIcon className={previewItemStyle} />
                        </div>
                    </Tooltip>
                )}

                {isWatch ? (
                    <Tooltip title="Share">
                        <div
                            className={`cursor-pointer`}
                            onClick={() => {
                                setIsShareModalVisible(true);
                            }}
                        >
                            <ShareAltOutlined className={previewItemStyle} />
                        </div>
                    </Tooltip>
                ) : null}
            </div>

            <Modal
                // title="Share this content"
                visible={isShareModalVisible}
                footer={null}
                onCancel={() => {
                    setIsShareModalVisible(false);
                }}
                bodyStyle={{
                    background: "#121920",
                    border: "1px solid #2A3036",
                }}
                closeIcon={<CloseOutlined className="text-primary-red" />}
                centered
            >
                <div className="text-3xl text-primary-white mb-4">
                    Share this content.
                </div>
                <div className="flex gap-4">
                    <FacebookShareButton url={window.document.URL}>
                        <img
                            src={facebookIcon}
                            alt="Facebook"
                            className={"w-16"}
                        />
                        <div className={"text-xs"}>Facebook</div>
                    </FacebookShareButton>
                    <TwitterShareButton url={window.document.URL}>
                        <img
                            src={twitterIcon}
                            alt="Twitter"
                            className={"w-16"}
                        />
                        <div className={"text-xs"}>Twitter</div>
                    </TwitterShareButton>
                    <EmailShareButton url={window.document.URL}>
                        <img src={emailIcon} alt="Email" className={"w-16"} />
                        <div className={"text-xs"}>Email</div>
                    </EmailShareButton>
                    <RedditShareButton url={window.document.URL}>
                        <img src={redditIcon} alt="Reddit" className={"w-16"} />
                        <div className={"text-xs"}>Reddit</div>
                    </RedditShareButton>
                    <PinterestShareButton
                        media={content.media.url}
                        url={window.document.URL}
                    >
                        <img
                            src={pinterestIcon}
                            alt="Pinterest"
                            className={"w-16"}
                        />
                        <div className={"text-xs"}>Pinterest</div>
                    </PinterestShareButton>
                </div>
                <div className={"space-y-2 mt-4"}>
                    <div>Share a link</div>

                    <div className="flex gap-4 items-center">
                        <div
                            className={
                                "truncate border-dashed border py-1 px-2"
                            }
                        >
                            {window.document.URL}
                        </div>
                        <CopyToClipboard text={window.document.URL}>
                            <Button type="link" className={"p-0 m-0"}>
                                <img
                                    src={copyIcon}
                                    alt="Copy to clipboard"
                                    className={"w-9"}
                                />
                            </Button>
                        </CopyToClipboard>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ContentActions;
