import React, { useEffect, useRef, useState } from "react";
import {Swiper, SwiperSlide } from "swiper/react";
import styles from "./index.module.less";
import SwiperCore from "swiper";

import {
    getAllContents,
    mapContentWithLength,
} from "../../shared/services/content/content.service";
import ContentItem from "./ContentItem";

const Reels = ({ filters }: any) => {
    const swiperRef = useRef<SwiperCore>(); 
    const [contents, setContents] = useState<any>([]);

    const sliderSettings = {
        440: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        680: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      };

    useEffect(() => {
        getAllContents(
            {
                ...filters,
                subCategory: ["REELS"],
            },
            {
                skip: 0,
                limit: 8,
                orderBy: "createdAt",
                orderByDir: "DESC",
            }
        )
            .then((resp) => {
                setContents(() => {
                    return mapContentWithLength(resp.response.data);
                });
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, [filters]);
    return (
      <div className={"space-y-6"}>
          <div className={"text-2xl md:text-4xl text-primary-white font-bold"}>
                1 minute inspiration
            </div>      
    
          <Swiper
            slidesPerView={3}
            breakpoints={sliderSettings}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            autoplay={{
              delay: 3000,
          }}
          loop={true}
          >
            <div className="arrow-buttons">
            <button onClick={() => swiperRef.current?.slidePrev()}>{'<'}</button>
            <button onClick={() => swiperRef.current?.slideNext()}
            style={{
              right:0
          }}>{'>'}</button>
            </div>
            {}
                 {contents.map((content: any) => {
                    return <SwiperSlide key={content.id} className={"sm:block"}
                    style={{ minWidth: 250 }}>
                     <ContentItem key={content.id} content={content} />
                    </SwiperSlide>;
              })}
            {}
            
          </Swiper>
        </div>
      );
};

export default Reels;
