import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { AuthContext } from "../context/auth";
import DashboardLayout from "../layouts/DashboardLayout";
import { getUserById } from "../services/user/user.service";

const ProtectedRoute = ({ component, ...restOfProps }: any) => {
    const { authState } = useContext(AuthContext);

    const history = useHistory();

    const [isReady, setIsReady] = useState(false);

    const validateSubscription = () => {
        if (authState?.user?.id) {
            getUserById(authState.user.id).then((resp) => {
                if(resp.response.role.name === 'Admin') {
                    resp.response.hasSubscription = true
                }
                if (!resp.response.hasSubscription) {
                    history.push("/subscription");
                }
            });
        }
    };

    useEffect(() => {
        if (!authState.loading) {
            setIsReady(true);
        }

        validateSubscription();

        history.listen((location) => {
            if (location.pathname !== "/subscription") {
                validateSubscription();
            }
        });
    }, [authState]);

    return (
        <>
            {!isReady ? (
                <div className="">Loading...</div>
            ) : (
                <>
                    {authState.token && authState.user ? (
                        <DashboardLayout>
                            <Route {...restOfProps} component={component} />
                        </DashboardLayout>
                    ) : (
                        <Redirect to={{ pathname: "/auth" }} />
                    )}
                </>
            )}
        </>
    );
};

export default ProtectedRoute;
