import { gql } from "@apollo/client";

export const getStripeProductsQuery = gql`
    query getStripeProducts {
        getStripeProducts {
            product {
                id
                name
                description
            }
            prices {
                id
                active
                unit_amount_decimal
            }
        }
    }
`;

export const getStripeProductByIdQuery = gql`
    query getStripeProductById($input: GetStripeProductByIdInput!) {
        getStripeProductById(input: $input) {
            product {
                id
                name
                description
            }
            prices {
                id
                active
                unit_amount_decimal
            }
        }
    }
`;

export const createSubscriptionMutation = gql`
    mutation createSubscription($input: CreateSubscriptionInput!) {
        createSubscription(input: $input) {
            id
            subscriptionId
            paymentMethodId
            customerId
            user {
                id
                firstName
                lastName
            }
            unsubscribeDate
        }
    }
`;

export const createSubscriptionWithCodeMutation = gql`
    mutation createSubscriptionWithCode($input: CreateSubscriptionWithCodeInput!) {
        createSubscriptionWithCode(input: $input) {
            code
        }
    }
`;

export const removeSubscriptionMutation = gql`
    mutation removeSubscription {
        removeSubscription {
            id
            subscriptionId
            paymentMethodId
            customerId
            user {
                id
                firstName
                lastName
            }
            unsubscribeDate
        }
    }
`;

export const cancelSubscriptionAtTheEndOfTheBillingCycleMutation = gql`
    mutation cancelSubscriptionAtTheEndOfTheBillingCycle {
        cancelSubscriptionAtTheEndOfTheBillingCycle {
            id
            subscriptionId
            paymentMethodId
            customerId
            user {
                id
                firstName
                lastName
            }
            unsubscribeDate
        }
    }
`;
