import React from 'react';
import Footer from "../../shared/components/Footer";

const Policy = () => {
    const olStyle = {
        listStyleType: 'decimal', // Set numbering style (e.g., decimal, lower-alpha, upper-roman)
        paddingLeft: '25px', // Add left padding to the list
    };

    const nestedol = {
        listStyleType: 'lower-alpha', // Set numbering style (e.g., decimal, lower-alpha, upper-roman)
        paddingLeft: '25px', // Add left padding to the list
    }
    return (
        <div className="md:absolute px-6 md:px-0 left-44  right-12 md:pt-10 flex flex-col min-h-screen-stiry">
            <div className="md:flex justify-between mb-4 md:mb-0">
                <div
                    className={`text-2xl md:text-3xl text-primary-white font-bold md:mb-10 mb-4`}
                >
                    Privacy policy
                </div>
                <div className={"md:flex justify-end gap-5 space-y-4"}>
                
                </div>
            </div>
            <div>
                <div className={`text-primary-white mb-4`}>
                    At Stiry, we highly value your privacy and are committed to ensuring the security and confidentiality of your personal information. This Privacy Policy outlines how we collect, use, and protect the personal information you provide to us, including your email address, name, and phone number. We encourage you to read this policy carefully to understand our practices and how your information will be utilized.
                </div> 
                <div className={`text-primary-white mb-4`}>
                    <ol style={olStyle}>
                        <li>Collection of Personal Information We collect personal information when you voluntarily provide it to us through our website, mobile applications, or other communication channels. This information may include your name, email address, phone number, and any other details you choose to provide. We only collect personal information that is relevant and necessary for the purposes described in this policy.</li>
                        <li>
                        Use of Personal Information We use the personal information you provide to us in the following ways:
                            <ol style={nestedol}>
                                <li>
                                    Communication: We may use your email address and phone number to communicate with you regarding your inquiries, requests, sending notifications and updates about Stiry, and other related matters. This includes responding to your messages, providing support, and sending important updates about our services.
                                </li>
                                <li>Personalization: We may use your personal information to personalize your experience with our services. This may include tailoring content, recommendations, and advertisements based on your preferences, location, and usage patterns.</li>
                                <li>Service Improvement: We may analyze the data you provide to us to improve our services, identify trends, and enhance user experience. This includes using aggregated and anonymized information for statistical purposes.</li>
                                <li>Legal Compliance: In certain cases, we may be required to use and disclose your personal information to comply with applicable laws, regulations, legal processes, or governmental requests.</li>
                            </ol>
                        </li>
                        <li>
                        Data Security and Retention We take appropriate measures to safeguard your personal information from unauthorized access, disclosure, alteration, or destruction. We utilize industry-standard security practices and technologies to protect your data. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
We will retain your personal information for as long as necessary to fulfill the purposes outlined in this policy, unless a longer retention period is required or permitted by law.
                        </li>
                        <li>
                        Sharing of Personal Information We do not sell, trade, or rent your personal information to third parties for marketing purposes. However, we may share your information with trusted service providers who assist us in operating our business and delivering our services. These third-party providers are contractually bound to keep your information confidential and are prohibited from using it for any other purposes.
We may also share your personal information if required to do so by law, to protect our rights, or to comply with a legal process. In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction, subject to the acquirer assuming the same obligations as set forth in this Privacy Policy.
                        </li>
                        <li>
                        Your Rights and Choices You have the right to access, update, and correct your personal information stored by us. If you wish to exercise these rights or have any concerns about the use of your information, please contact us using the information provided at the end of this policy.
You may also choose to unsubscribe from our communications at any time by following the instructions provided in our emails or by contacting us directly.
                        </li>
                        <li>
                        Changes to this Privacy Policy We reserve the right to modify or update this Privacy Policy from time to time. Any changes will be effective upon posting the revised policy on our website. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
                        </li>
                        Contact Us If you have any questions, comments, or concerns about this Privacy Policy or our data practices, please contact us at support@stiry.com.
By using our services you hereby agree to these policies. If you wish to opt out please unsubscribe to <a href="mailto:stiryplus.com">stiryplus.com</a> and send us an email at <a href="mailto:support@stiry.com">support@stiry.com</a> 
                    </ol>
                </div>
            </div>
             
             
             
            <div className="flex-grow flex flex-col justify-end">
                <Footer />
            </div>
        </div>
    );
}

export default Policy;
