import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import CheckoutModal from "../../shared/components/CheckoutModal";
import {
  createSubscription,
  getStripeProducts,
  createSubscriptionWithCode,
} from "../../shared/services/stripe/stripe.service";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Footer from "../../shared/components/Footer";

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE));

const Subscription = () => {
  const history = useHistory();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update window width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Calculate the desired width based on the window width
  const getWidth = () => {
    if (windowWidth >= 768) {
      return "60%";
    }
    return "90%";
  };
  const [products, setProducts] = useState([]);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({
    product: {
      id: "",
    },
    price: {
      id: "",
    },
  });
  const [textboxValue, setTextboxValue] = useState(""); // State for textbox value

  const handleSubmit = () => {
    createSubscriptionWithCode({
      code: textboxValue,
    }).then(() => {
      toast.success("You are now successfully subscribed.");
      setShowCheckoutModal(false);
      history.push("/");
    });
  };

  useEffect(() => {
    getStripeProducts().then((resp) => {
      setProducts(resp.response);
    });
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <div className="md:left-44 right-12 pt-10 flex flex-col min-h-screen-stiry">
        <div className="flex justify-center">
          <div className={`text-3xl text-primary-white font-bold mb-10`}>
            Subscription
          </div>
        </div>

        <div className="grid justify-center mb-10">
          {products.map((product: any) => {
            return (
              <div
                key={product.product.id}
                className="bg-tertiary-dark rounded-lg p-4"
                
              >
                <div className="text-center">
                  <div className="text-2xl mb-2">{product.product.name}</div>
                  <div className="mb-4">{product.product.description}</div>

                  {product.prices.map((price: any) => {
                    return (
                      <div key={price.id} className="space-y-4">
                        <div>${price.unit_amount_decimal / 100}</div>
                        <div>
                          <Button
                            type="primary"
                            size="large"
                            onClick={() => {
                              setSelectedProduct({
                                product: product.product,
                                price,
                              });
                              setShowCheckoutModal(true);
                            }}
                          >
                            Select
                          </Button>
                        </div>
                        <div>OR</div>
                        <div>
                          <Input
                            placeholder="Enter code"
                            value={textboxValue}
                            onChange={(e) =>
                              setTextboxValue(e.target.value)
                            }
                          />
                        </div>
                        <div>
                          <Button type="primary" onClick={handleSubmit}>
                            Submit
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex-grow flex flex-col justify-end">
          <Footer />
        </div>

        <CheckoutModal
          showModal={showCheckoutModal}
          onClose={() => setShowCheckoutModal(false)}
          onSuccess={(paymentMethodId: string) => {
            createSubscription({
              paymentMethodId,
              productId: selectedProduct.product.id,
              priceId: selectedProduct.price.id,
            }).then(() => {
              toast.success("You are now successfully subscribed.");
              setShowCheckoutModal(false);
              history.push("/");
            });
          }}
        />
      </div>
    </Elements>
  );
};

export default Subscription;
