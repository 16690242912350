import React from "react";
import "./App.less";
import { AuthProvider } from "./shared/context/auth";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "./modules/dashboard";
import NotFound from "./shared/components/NotFound";
import ProtectedRoute from "./shared/components/ProtectedRoute";
import Auth from "./modules/auth";
import Content from "./modules/content";
import Channel from "./modules/channel";
import Favorite from "./modules/favorite";
import Watchlist from "./modules/watchlist";
import SeeAll from "./modules/channel/SeeAll";
import Search from "./modules/search";
import Profile from "./modules/profile";
import Subscription from "./modules/subscription";
import Policy from "./modules/policy";

function App() {
    return (
        <AuthProvider>
            <Router>
                <Switch>
                    <ProtectedRoute exact path="/" component={Dashboard} />

                    <ProtectedRoute exact path="/search" component={Search} />

                    <ProtectedRoute
                        exact
                        path="/content/:id/:state?"
                        component={Content}
                    />

                    <ProtectedRoute
                        exact
                        path="/channel/:id"
                        component={Channel}
                    />

                    <ProtectedRoute
                        exact
                        path="/channel/:id/all"
                        component={SeeAll}
                    />
                    <ProtectedRoute
                        exact
                        path="/favorites"
                        component={Favorite}
                    />

                    <ProtectedRoute
                        exact
                        path="/watchlist"
                        component={Watchlist}
                    />

                    <ProtectedRoute exact path="/profile" component={Profile} />

                    <ProtectedRoute
                        exact
                        path="/subscription"
                        component={Subscription}
                    />
                    <ProtectedRoute
                        exact
                        path="/privacy-policy"
                        component={Policy}
                    />

                    <Route path="/" component={Auth} />

                    <Route path="*/*" component={NotFound} />
                </Switch>
                <ToastContainer />
            </Router>
        </AuthProvider>
    );
}

export default App;
