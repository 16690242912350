import React, { useContext, useEffect, useState } from "react";
import logoImage from "./../../../assets/images/logo.svg";
import { useHistory } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import LeftSidebar from "../components/LeftSidebar";
import { getChannels } from "../services/channel/channel.service";
import { Input } from "antd";
import { MenuOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./index.module.less";
import { take } from "lodash";
import { AuthActions, AuthContext } from "../context/auth";
import ChannelsModal from "../components/ChannelsModal";

const DashboardLayout = ({ children }: any) => {
    const [isMobile, setIsMobile] = useState(window.outerWidth < 1250);

    const [channels, setChannels] = useState([]);

    const { authState, dispatch } = useContext(AuthContext);

    const [showMenu, setShowMenu] = useState(false);

    const history = useHistory();

    const [showChannelModal, setShowChannelModal] = useState(false);

    useEffect(() => {
        getChannels("ASC").then((resp) => {
            setChannels(resp.response.data);
        });
    }, []);

    useEffect(() => {
        const historyListener = history.listen(() => {
            setShowMenu(false);
        });

        return () => {
            historyListener();
        };
    }, []);

    useEffect(() => {
        const resizeListener = (ev: any) => {
            setIsMobile(window.outerWidth < 1363);
        };

        window.addEventListener("resize", resizeListener);

        return () => {
            window.removeEventListener("resize", resizeListener, false);
        };
    }, []);

    return (
        <>
            <header
                className={`z-50 fixed w-full px-6`}
                style={{
                    height: 83,
                    background:
                        "linear-gradient(0deg, rgba(36,41,46,0) 10%, rgba(18,25,32,1) 80%)",
                        zIndex: 100,
                }}
            >
                <div
                    className={`h-full ${
                        isMobile ? "pt-6" : "flex"
                    } items-center justify-between`}
                >
                    <div className="flex justify-between items-center relative gap-10  md:pt-0 mr-10">
                        <Link to={"/"} className={"flex-shrink-0"}>
                            <img src={logoImage} alt="logo" className="h-6" />
                        </Link>

                        {isMobile ? (
                            <div className="space-x-4">
                                <Link to={"/search"}>
                                    <SearchOutlined />
                                </Link>
                                <button>
                                    <MenuOutlined
                                        onClick={() => {
                                            setShowMenu(!showMenu);
                                        }}
                                    />
                                </button>
                            </div>
                        ) : (
                            <Link to={"/search"}>
                                <Input
                                    placeholder="What moves you?"
                                    prefix={<SearchOutlined />}
                                    suffix={null}
                                    className={styles.navSearcWrapper}
                                />
                            </Link>
                        )}
                        {showMenu ? (
                            <div className="absolute left-0 right-0 top-8 py-2 bg-tertiary-dark rounded px-4 gap-4 flex flex-col">
                                <Link to={"/"}>Home</Link>
                                <Link to={"/watchlist"}>Watchlist</Link>
                                <Link to={"/favorites"}>Favorites</Link>
                                <div
                                    onClick={() => {
                                        setShowChannelModal(true);
                                        setShowMenu(false);
                                    }}
                                >
                                    Channels
                                </div>
                                <Link to={"/profile"} className={"capitalize"}>
                                    {authState.user.firstName}&nbsp;
                                    {authState.user.lastName}
                                </Link>
                                <Link
                                    to={"#"}
                                    onClick={() => {
                                        dispatch({
                                            action: AuthActions.LOGOUT,
                                            data: {},
                                        });
                                    }}
                                >
                                    Logout
                                </Link>
                            </div>
                        ) : null}
                    </div>
                    {!isMobile ? (
                        <div className="flex gap-2 flex-wrap">
                            {take(channels, 5).map((channel: any) => {
                                return (
                                    <Link
                                        key={channel.id}
                                        to={"/channel/" + channel.id}
                                    >
                                        <span className="tag-button small">
                                            {channel.name}
                                        </span>
                                    </Link>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
            </header>

            <main>
                <LeftSidebar></LeftSidebar>

                <div style={{ paddingTop: 83 }}>{children}</div>
            </main>

            <ChannelsModal
                showModal={showChannelModal}
                onClose={() => setShowChannelModal(false)}
            />
        </>
    );
};

export default DashboardLayout;
